import { Link } from 'react-router-dom';
import './Footer.css';
import logo from './logo.svg';
import WaveAnimation from '../../UI/WaveAnimation/WaveAnimation';
import { AnimatedPlainLink } from '../../UI/Link';

const Footer = () => {
  return (
    <div>
      <WaveAnimation />
      <footer className="Footer">
        <img src={logo} className="Footer__logo" alt="logo" />
        <div>
          Built with{' '}
          <span role="img" aria-label="Heart emoji">
            ❤️
          </span>
          {' using '}
          <span role="img" aria-label="Atom emoji" title="React.js">
            ⚛️
          </span>
          {' and '}
          <span
            role="img"
            aria-label="Nail polish emoji"
            title="styled components"
          >
            💅🏾
          </span>
        </div>

        <hr />
        <ul className="Footer__links">
          <li className="Footer__link-item">
            <AnimatedPlainLink as={Link} to="/skills">
              Skills
            </AnimatedPlainLink>
          </li>
          <li className="Footer__link-item">|</li>
          <li className="Footer__link-item">
            <AnimatedPlainLink as={Link} to="/profile">
              Profile
            </AnimatedPlainLink>
          </li>
        </ul>
        <hr />
        <p>All content &copy; 2014-Present</p>
      </footer>
    </div>
  );
};

export default Footer;
