import './WaveAnimation.css';

const WaveAnimation = () => {
  return (
    <div className="WaveAnimation__container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 40"
        preserveAspectRatio="none"
      >
        <path d="M 0 32.6868 C 200 32.6868 363.434 7.31325 563.434 7.31325 C 763.434 7.31325 800 32.6868 1000 32.6868 L 1000 50 L 0 50 L 0 32.6868 Z">
          <animate
            attributeName="d"
            begin="0s"
            dur="5s"
            repeatCount="indefinite"
            values="
              M0,0 C200,7.11236625e-15 200,40 400,40 C600,40 800,0 1000,0 L1000,50 L0,50 L0,0 Z;
              M0,40 C200,40 400,0 600,0 C800,0 800,40 1000,40 L1000,50 L0,50 L0,40 Z;
              M0,30 C200,30 200,0 400,0 C600,0 800,40 1000,40 L1000,50 L0,50 L0,30 Z;
              M0,0 C200,7.11236625e-15 200,40 400,40 C600,40 800,0 1000,0 L1000,50 L0,50 L0,0 Z;"
          />
        </path>
      </svg>
    </div>
  );
};

export default WaveAnimation;
