import styled from 'styled-components';

export const SocialIcon = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: box-shadow 150ms ease-in-out;
`;

export const SocialList = styled.ul`
  list-style-type: none;
  margin-left: 0;
  margin-top: 32px;
  padding-left: 0;
`;

export const SocialListItem = styled.li`
  border-radius: 50%;
  box-shadow: var(--box-shadow-default);
  display: inline-block;
  margin-right: 10px;
  transition: transform var(--animation-speed-fast)
    var(--animation-accel-slow-to-fast);

  &:hover {
    transform: translateY(-5px);
  }

  a:focus {
    outline: 0;

    ${SocialIcon} {
      box-shadow: var(--outline-default);
    }
  }
`;
