import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import logoImg from './logo.png';
import { BoldHeaderLink } from '../../UI/Link';
import { DarkmodeToggle } from '../../UI/Button';

const routes = [
  {
    path: '/skills',
    title: 'Skills',
  },
  {
    path: '/profile',
    title: 'Profile',
  },
];

const Header = () => {
  const { pathname } = useLocation();

  const headerLinks = (
    <nav>
      <ul className="Menu">
        {routes.map((route, index) => (
          <li key={index} className="Menu__item">
            <BoldHeaderLink
              active={pathname === route.path}
              as={Link}
              to={route.path}
            >
              {route.title}
            </BoldHeaderLink>
          </li>
        ))}
        <li className="Menu__item">
          <div className="Darkmode__button-wrapper">
            <DarkmodeToggle />
          </div>
        </li>
      </ul>
    </nav>
  );

  return (
    <header className="Header">
      <div className="App__container Header__content--flex">
        <div className="Header__left">
          <Link to="/">
            <img
              className="Header__logo"
              src={logoImg}
              alt="Elliott Creates"
              title="Elliott Creates | Home"
            />
          </Link>
        </div>

        <div className="Header__right">{headerLinks}</div>
      </div>
    </header>
  );
};

export default Header;
