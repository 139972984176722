import React from 'react';
import styled from 'styled-components';

interface TooltipInnerProps {
  inverted?: boolean;
  minWidth?: string;
  offset?: string;
}

const TooltipInner = styled.div<TooltipInnerProps>`
  background-color: ${({ inverted }) =>
    inverted ? 'var(--white)' : 'var(--gray-900)'};
  border-radius: var(--border-radius-default);
  color: ${({ inverted = false }) =>
    inverted ? 'var(--gray-900)' : 'var(--white)'};
  font-size: 15px;
  font-weight: 600;
  left: 50%;
  max-width: 200px;
  min-width: ${({ minWidth = '100' }) => `${minWidth}px`};
  opacity: 0;
  padding: 4px 8px;
  position: absolute;
  text-align: center;
  top: ${({ offset = '0' }) => `calc(100% - 60px - ${offset}px)`};
  transform: translateX(-50%) scale(0);

  // Animations
  transition-duration: 0.3s;
  transition-property: transform, opacity;
  transition-timing-function: ease-in-out;
`;

interface TooltipTriggerProps {
  delay?: string;
}

const TooltipTrigger = styled.div<TooltipTriggerProps>`
  display: inline-block;
  position: relative;
  z-index: var(--zindex-tooltip);

  &:hover ${TooltipInner} {
    transition-delay: ${({ delay }) => (delay ? `${delay}s` : '0.5s')};
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }
`;

interface Props {
  content: string;
  inverted?: boolean;
  children: React.ReactNode;
  delay?: string;
  offset?: string;
  minWidth?: string;
}

const Tooltip = ({
  content,
  inverted = false,
  delay,
  offset = '0',
  minWidth = '100',
  children,
}: Props) => {
  return (
    <TooltipTrigger delay={delay}>
      <TooltipInner inverted={inverted} offset={offset} minWidth={minWidth}>
        {content}
      </TooltipInner>
      {children}
    </TooltipTrigger>
  );
};

export default Tooltip;
