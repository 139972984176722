import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProjectDetail from './ProjectDetail';

const Projects = () => {
  return (
    <div>
      <Routes>
        <Route path=":slug" element={<ProjectDetail />} />
      </Routes>
    </div>
  );
};

export default Projects;
