import React from 'react';
import styled, { css } from 'styled-components';

const PlainLink = styled.a`
  text-decoration: none;
`;

const StyledLink = styled.a`
  color: ${({ color }) => (color ? `var(--${color})` : 'var(--primary-color)')};
  cursor: pointer;
  display: inline-block;
  line-height: inherit;
  position: relative;
  text-decoration: none;
`;

interface AnimatedPlainLinkProps {
  semiBold?: boolean;
  href?: string;
  title?: string;
}

export const AnimatedPlainLink = styled(StyledLink)<AnimatedPlainLinkProps>`
  &::after {
    background: currentColor;
    content: '';
    display: block;
    height: 1px;
    opacity: 0;
    position: absolute;
    top: 100%;
    transition: top var(--animation-speed-fast)
        var(--animation-accel-fast-to-slow),
      opacity var(--animation-speed-fast) var(--animation-accel-fast-to-slow);
    width: calc(100% - 2px);
  }

  &:hover:not(:focus) {
    &::after {
      opacity: 1;
      top: calc(100% - 2px);
    }
  }

  ${({ semiBold }) =>
    semiBold
      ? css`
          font-weight: 600;
        `
      : ''};
`;

interface HeaderLinkProps {
  active?: boolean;
}

const HeaderLink = styled(StyledLink)<HeaderLinkProps>`
  &::after {
    background: currentColor;
    content: '';
    display: block;
    height: 1px;
    opacity: 0;
    position: absolute;
    top: 100%;
    transition: top var(--animation-speed-fast)
        var(--animation-accel-fast-to-slow),
      opacity var(--animation-speed-fast) var(--animation-accel-fast-to-slow);
    width: calc(100% - 2px);
  }

  &:hover,
  &:focus {
    color: #274ad4;
  }

  ${({ active }) =>
    active
      ? `&::after {
        opacity: 1;
        top: calc(100% + 1px);
    }`
      : ''}
`;

export const BoldHeaderLink = styled(HeaderLink)`
  font-weight: 600;
`;

interface ExternalLinkProps {
  href: string;
  className?: string;
  title?: string;
  children: React.ReactNode;
}

export const ExternalLink = ({
  href,
  className,
  title,
  children,
}: ExternalLinkProps) => {
  return (
    <PlainLink
      href={href}
      className={className}
      title={title}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </PlainLink>
  );
};
