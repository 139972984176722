import React, { PureComponent } from 'react';
import Autosuggest from 'react-autosuggest';
import './SearchSuggest.css';
import { staticUrlPath } from '../../data/defaults';
import { LANGUAGES } from './constants';
import type { Suggestion } from '../../types/interfaces';

const getSuggestions = (value: string): Suggestion[] => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? []
    : LANGUAGES.filter(
        (lang) => lang.name.toLowerCase().slice(0, inputLength) === inputValue,
      );
};

const getSuggestionValue = (suggestion: Suggestion) => suggestion.name;

const renderSuggestion = (suggestion: Suggestion) => {
  const languageImgScr = `${staticUrlPath}/logos/${suggestion.image}.svg`;

  return (
    <div>
      <img
        src={languageImgScr}
        className="Suggestion__image"
        alt={`${suggestion.name} logo`}
      />
      <span className="Suggestion__text">{suggestion.name}</span>
    </div>
  );
};

interface Props {
  handleBubbleEvent: (value: string) => void;
}

interface State {
  value: string;
  suggestions: Suggestion[];
}

class SearchSuggest extends PureComponent<Props, State> {
  state: State = {
    value: '',
    suggestions: [],
  };

  handleClearClick = () => {
    this.setState({
      value: '',
    });
    this.bubbleEvent('');
  };

  onChange = (
    _event: React.FormEvent<HTMLInputElement>,
    { newValue }: { newValue: string },
  ) => {
    this.setState({
      value: newValue,
    });
    this.bubbleEvent(newValue);
  };

  bubbleEvent = (value: string) => {
    this.props.handleBubbleEvent(value);
  };

  onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    this.setState({
      suggestions: getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: 'For example: ES6, React or Rails',
      value,
      onChange: this.onChange,
    };

    return (
      <div className="react-autosuggest__parent-container">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          // @ts-ignore: TODO: TSFixMe -> Unknown error
          inputProps={inputProps}
        />
        {value && (
          <div className="CloseIcon__container">
            <button className="CloseIcon" onClick={this.handleClearClick}>
              X
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default SearchSuggest;
