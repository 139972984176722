import styled from 'styled-components';
import { staticUrlPath } from '../../data/defaults';

const TechListLogo = styled.img`
  max-height: 30px;
  max-width: 60px;
  margin-right: 10px;
`;

interface Props {
  items: string[];
}

const TechList = ({ items }: Props) => {
  return (
    <p className="TechList">
      {items.map((item) => {
        const imgSrc = `${staticUrlPath}/logos/${item}.svg`;

        return (
          <TechListLogo
            key={item}
            src={imgSrc}
            alt={`${item} logo`}
            title={item}
          />
        );
      })}
    </p>
  );
};

export default TechList;
