export const externalLinkProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
};

export const getCoordinates = (index: number) => {
  switch (index) {
    // Left column
    case 0:
    case 3:
    case 6:
      return {
        x: '-90%',
        y: '88%',
      };
    // Right column
    case 2:
    case 5:
      return {
        x: '0%',
        y: '88%',
      };
    // Center column
    default:
      return {
        x: '-50%',
        y: '88%',
      };
  }
};
