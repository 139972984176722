import { motion } from 'framer-motion';
import { sharedTransition } from './constants';
import Footer from '../Layout/Footer/Footer';

// TODO: TSFixMe - Add types
export const withFadeInMotion = (Comp: any) => (props: any) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={sharedTransition}
    >
      <Comp {...props} />
      <Footer />
    </motion.div>
  );
};
