export const LANGUAGES = [
  {
    name: 'JavaScript',
    image: 'javascript',
  },
  {
    name: 'CSS3',
    image: 'css3',
  },
  {
    name: 'HTML5',
    image: 'html5',
  },
  {
    name: 'ES6',
    image: 'es6',
  },
  {
    name: 'Ruby',
    image: 'ruby',
  },

  {
    name: 'PHP',
    image: 'php',
  },
  {
    name: 'Sass/SCSS',
    image: 'sass',
  },
  {
    name: 'Bootstrap 4',
    image: 'bootstrap',
  },
  {
    name: 'Ruby on Rails',
    image: 'rails',
  },
  {
    name: 'Next.js',
    image: 'nextjs',
  },
  {
    name: 'React',
    image: 'react',
  },
  {
    name: 'Gulp',
    image: 'gulp',
  },
  {
    name: 'Grunt',
    image: 'grunt',
  },
  {
    name: 'Node',
    image: 'node',
  },
  {
    name: 'Webpack',
    image: 'webpack',
  },
  {
    name: 'npm',
    image: 'npm',
  },
  {
    name: 'yarn',
    image: 'yarn',
  },
  {
    name: 'bower',
    image: 'bower',
  },
  {
    name: 'Git',
    image: 'git',
  },
  {
    name: 'GitLab',
    image: 'gitlab',
  },
  {
    name: 'GitHub',
    image: 'github',
  },
  {
    name: 'Firebase',
    image: 'firebase',
  },
  {
    name: 'Sketch',
    image: 'sketch',
  },
  {
    name: 'JIRA',
    image: 'jira',
  },
  {
    name: 'Confluence',
    image: 'confluence',
  },
  {
    name: 'Slack',
    image: 'slack',
  },
  {
    name: 'GraphQL',
    image: 'graphql',
  },
];
