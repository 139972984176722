import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moonIcon from './moon.svg';
import sunIcon from './sun.svg';

interface IconWrapperProps {
  top: number;
}

const IconWrapper = styled.div<IconWrapperProps>`
  position: relative;
  transition: top 0.44s ease-in-out;
  transition-delay: 0.22s;
  top: ${({ top = -2 }) => `${top}px`};
`;

const SunIcon = () => <img src={sunIcon} alt="sun" className="Icon--right" />;
const MoonIcon = () => (
  <img src={moonIcon} alt="moon" className="Icon--right" />
);

const currentHour = new Date().getHours();
const isNightTime = currentHour >= 19 || currentHour <= 6 ? true : false;

export const DarkmodeToggle = () => {
  const [isDarkmode, setDarkmode] = useState(true);

  useEffect(() => {
    const darkmodeSetting = localStorage && localStorage.getItem('darkMode');
    const _isDarkmode = darkmodeSetting === 'true';

    if (_isDarkmode) document.body.classList.add('dark');

    setDarkmode(darkmodeSetting ? _isDarkmode : isNightTime);
  }, []);

  const handleToggleDarkmodeClick = () => {
    const _isDarkMode = !isDarkmode;
    setDarkmode(_isDarkMode);
    localStorage && localStorage.setItem('darkMode', _isDarkMode.toString());

    if (_isDarkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  };

  return (
    <button
      className="Button"
      onClick={handleToggleDarkmodeClick}
      title="Toggle darkmode"
    >
      <IconWrapper top={isDarkmode ? 30 : -5}>
        <SunIcon />
      </IconWrapper>
      <IconWrapper top={isDarkmode ? -29 : 30}>
        <MoonIcon />
      </IconWrapper>
    </button>
  );
};
