import styled from 'styled-components';

export const ResultListItem = styled.li`
  text-overflow: ellipsis;
  font-size: 17px;
`;

export const ResultsListWrapper = styled.div`
  align-content: stretch;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const ResultListContainer = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }

  @media (min-width: 992px) {
    width: 20%;
  }
`;

export const ResultListTitle = styled.h5`
  font-weight: 600;
`;
