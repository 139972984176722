import './MessageBlock.css';
import WithAnimatedFadeInOut from '../../Motion/WithAnimatedFadeInOut';
import type { Content } from '../../../types/interfaces';

interface MessageBlockProps {
  content: Content;
  isVisible: boolean;
  handleClick?: () => void;
}

const MessageBlock = ({
  content,
  isVisible,
  handleClick,
}: MessageBlockProps) => {
  let closeBtn: React.ReactNode | null = null;
  const classes = content.classNames || '';

  if (handleClick) {
    closeBtn = (
      <button className="Close" onClick={handleClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 10 10"
          width="10"
          height="10"
        >
          <path d="M5.9 5l3.9-3.9c.2-.2.2-.6 0-.9-.2-.2-.6-.2-.9 0L5 4.1 1.1.2C.9 0 .5 0 .2.2s-.2.6 0 .9L4.1 5 .2 8.9c-.2.2-.2.6 0 .9.1.1.3.2.4.2s.3-.1.4-.2l4-3.9 3.9 3.9c.2.1.3.2.5.2s.3-.1.4-.2c.2-.2.2-.6 0-.9L5.9 5z" />
        </svg>
      </button>
    );
  }

  return (
    <WithAnimatedFadeInOut
      isVisible={isVisible}
      renderComponent={() => (
        <div className={`MessageBlock ${classes}`}>
          {closeBtn}
          <img src={content.image} alt="No result" />
          <h3 className="MessageBlock__title">{content.title}</h3>
          <p className="MessageBlock__copy">{content.copy}</p>
        </div>
      )}
    />
  );
};

export default MessageBlock;
