import { useEffect } from 'react';

interface Props {
  smooth?: boolean;
}

const ScrollToTopOnMount = ({ smooth = false }: Props) => {
  useEffect(() => {
    if (smooth) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    } else {
      window.scrollTo(0, 0);
    }
  }, [smooth]);

  return null;
};

export default ScrollToTopOnMount;
