import { motion } from 'framer-motion';
import './styles.css';
import ProjectsList from '../../components/ProjectsList/ProjectsList';
import ScrollToTopOnMount from '../../components/Utils/ScrollToTopOnMount';
import { withFadeInMotion } from '../../components/Motion/withFadeInMotion';
import { CompanyBlock, CommunityBlock } from './partials';

const Home = () => {
  return (
    <>
      <ScrollToTopOnMount smooth />

      {/* HERO */}
      <div className="App__content-block--100 Hero__block">
        <div className="App__container">
          <motion.h1 exit={{ opacity: 0 }} className="App__section-subheader">
            Hi! I'm <span className="App__highlight">Elliott</span> -- an{' '}
            <span className="App__swish-highlight">Engineering Manager</span>{' '}
            and Software Engineer with over a decade of experience. Currently leading the frontend team at <span className="App__highlight">Mercari.</span>
          </motion.h1>
        </div>
      </div>

      {/* PROJECTS */}
      <div className="App__content-block--100 Projects__block">
        <div className="App__container App__block-grid">
          <ProjectsList />
        </div>
      </div>

      {/* OTHER */}
      <CompanyBlock />
      <CommunityBlock />
    </>
  );
};

export default withFadeInMotion(Home);
