import type { Skills } from '../../types/interfaces';
import {
  ResultListItem,
  ResultsListWrapper,
  ResultListContainer,
  ResultListTitle,
} from './components';

const ResultsList = ({ skills }: { skills: Skills }) => {
  return (
    <ResultsListWrapper>
      {Object.keys(skills).map((key: string, i: number) => {
        let skillTitle =
          skills[key].length > 0 ? (
            <ResultListTitle>{key}</ResultListTitle>
          ) : null;

        if (skills[key].length < 1) {
          return false;
        }

        return (
          <ResultListContainer key={key + i}>
            {skillTitle}
            <ul style={{ marginBottom: '2rem' }}>
              {skills[key].map((item, i) => {
                return <ResultListItem key={key + i}>{item}</ResultListItem>;
              })}
            </ul>
          </ResultListContainer>
        );
      })}
    </ResultsListWrapper>
  );
};

export default ResultsList;
