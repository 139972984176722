import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import './ProjectItem.css';
import { staticUrlPath } from '../../../data/defaults';
import TechList from '../../TechList/TechList';
import { LazyLoadWebpImage } from '../../UI/Image';
import type { Project } from '../../../types/interfaces';
import { getCoordinates } from '../../../utilities';

interface Props {
  index: number;
  project: Project;
}

const ProjectItem = ({ project, index }: Props) => {
  const imgSrc = `${staticUrlPath}/img/${project.image}.png`;
  const imgSrcWebp = `${staticUrlPath}/img/${project.image}.webp`;
  const projectDetailUrl = `/projects/${project.name}`;

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.25,
  });

  return (
    <motion.div
      ref={ref}
      animate={{ opacity: inView ? 1 : 0 }}
      transition={{
        duration: 0.4,
        ease: 'easeOut',
      }}
      className="ProjectItem__wrapper App__block-grid-item"
    >
      <Link
        to={projectDetailUrl}
        state={{
          coordinates: getCoordinates(index),
        }}
      >
        <div className="ProjectItem__img-wrapper">
          <figure>
            <LazyLoadWebpImage
              src={imgSrc}
              webp={imgSrcWebp}
              height={249}
              alt={project.name}
            />
          </figure>
          <span
            className={
              'ProjectItem__category ' +
              (project.category === 'personal'
                ? 'ProjectItem__category--default'
                : '')
            }
            aria-hidden="true"
          >
            {project.category}
          </span>
        </div>
        <div className="ProjectItem__content">
          <h4 className="ProjectItem__title">{project.title}</h4>
          <p className="ProjectItem__copy">{project.description}</p>
          <TechList items={project.technologies} />
        </div>
      </Link>
    </motion.div>
  );
};

export default ProjectItem;
