import { SocialList, SocialListItem, SocialIcon } from './components';
import Tooltip from '../Tooltip';
import { ExternalLink } from '../Link';
import type { Account } from '../../../types/interfaces';

interface Props {
  accounts: Account[];
}

const Social = ({ accounts }: Props) => {
  return (
    <SocialList>
      {accounts.map((account: Account) => {
        const processEnvPublicUrl = process.env.PUBLIC_URL || '';
        const iconSrc = `${processEnvPublicUrl}/logos/${account.icon}`;

        return (
          <SocialListItem key={account.name}>
            <ExternalLink href={account.url}>
              <Tooltip content={account.name} offset="16" delay="0">
                <SocialIcon
                  src={iconSrc}
                  className="Social__icon"
                  alt={account.name}
                />
              </Tooltip>
            </ExternalLink>
          </SocialListItem>
        );
      })}
    </SocialList>
  );
};

export default Social;
