import ProjectItem from './ProjectItem/ProjectItem';
import { PROJECTS } from '../../data/constants';

const ProjectsList = () => {
  return (
    <>
      {PROJECTS.map((project, index) => {
        return (
          <ProjectItem key={project.name} project={project} index={index} />
        );
      })}
    </>
  );
};

export default ProjectsList;
