import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import type { Project } from '../../types/interfaces';
import './ProjectDetail.css';
import { staticUrlPath } from '../../data/defaults';
import TechList from '../TechList/TechList';
import { AnimatedPlainLink, ExternalLink } from '../UI/Link';
import ScrollToTopOnMount from '../Utils/ScrollToTopOnMount';
import { currentDate } from '../Utils/dates';
import { sharedTransition } from '../Motion/constants';
import { PROJECTS } from '../../data/constants';
import Error404 from '../../pages/error';
import { LazyLoadWebpImage } from '../UI/Image';

const HeroTextWrapper = styled.div`
  text-align: left;
`;

const ProjectDetail = () => {
  const { slug } = useParams();
  const location = useLocation();
  const coordinates = location?.state?.coordinates;

  const project: Project | undefined = PROJECTS.find(
    ({ name: projectId }) => projectId === slug,
  );

  if (!project) return <Error404 />;

  const {
    themeColor,
    title,
    description,
    overview,
    dates,
    role,
    category,
    image,
    url,
    technologies,
    otherTools,
    contributions,
  } = project;

  const imgSrc = `${staticUrlPath}/img/${image}.png`;
  const imgSrcWebp = `${staticUrlPath}/img/${image}.webp`;
  const hasContributions = contributions && contributions.length > 0;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={`${currentDate.toString()} - ${description}`}
          key="description"
        />
      </Helmet>
      <ScrollToTopOnMount />
      <motion.div
        className="ProjectDetail__hero"
        animate={{ backgroundColor: themeColor }}
        initial={{ backgroundColor: '#f8f9fa' }}
        transition={{ ...sharedTransition, duration: 0.75 }}
      >
        <div className="App__container Hero__content">
          <HeroTextWrapper
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ...sharedTransition, duration: 0.75 }}
          >
            <p className="Hero__category">{category}</p>
            <h1 className="Hero__title">{title}</h1>
          </HeroTextWrapper>
          <motion.figure
            initial={{
              width: 374,
              borderTopRightRadius: 24,
              borderTopLeftRadius: 24,
              scale: 1.02,
              zIndex: 10,
              ...coordinates,
            }}
            animate={{
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              width: 525,
              y: 0,
              x: 0,
              scale: 1,
            }}
            transition={{ ...sharedTransition, duration: 0.75 }}
          >
            <figure>
              <LazyLoadWebpImage
                src={imgSrc}
                webp={imgSrcWebp}
                height={350}
                alt={title}
                className="Hero__img Util__hidden"
              />
            </figure>
          </motion.figure>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ...sharedTransition, duration: 1.2 }}
        className="App__content-block--50"
      >
        <div className="App__container App__container--narrow ProjectDetail__content">
          <h2 className="App__section-title">Overview</h2>
          <p className="App__section-copy">{overview}</p>

          <dl>
            <dt>Dates</dt>
            <dd>{dates}</dd>
            <dt>Role</dt>
            <dd>{role}</dd>
            <dt>Technologies/Tools</dt>
            <dd>
              <TechList items={[...technologies, ...otherTools]} />
            </dd>
          </dl>

          {url && (
            <p className="ProjectDetail__action">
              <ExternalLink
                href={url}
                className="Button Button--primary Button--rounded"
                title="See how it works!"
              >
                Visit Site
              </ExternalLink>
            </p>
          )}

          {hasContributions && (
            <dl className="ProjectDetail__contributions">
              <dt>Contributions</dt>
              <dd>
                <ul>
                  {contributions.map((item: string, index: number) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ul>
              </dd>
            </dl>
          )}

          <hr />

          <p className="Util__text-center">
            <AnimatedPlainLink as={Link} to="/" title="Home">
              Back to Home
            </AnimatedPlainLink>
          </p>
        </div>
      </motion.div>
    </>
  );
};

export default ProjectDetail;
