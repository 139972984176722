import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import { staticUrlPath } from '../../../data/defaults';

export const COMPANIES = [
  {
    name: 'Mercari',
    image: 'mercari.svg',
  },
  {
    name: 'Metaps',
    image: 'metaps.svg',
  },
  {
    name: 'Spike',
    image: 'spike.svg',
  },
  {
    name: 'Lendia',
    image: 'lendia.svg',
  },
  {
    name: 'Baberu',
    image: 'baberu.svg',
  },
  {
    name: 'Metaps Links',
    image: 'metaps_links.svg',
  },
];

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export const CompanyBlock = () => {
  const animation = useAnimation();
  const { ref, inView } = useInView({ rootMargin: '100px 0px' });

  useEffect(() => {
    if (inView) {
      animation.start('visible');
    } else {
      animation.start('hidden');
    }
  }, [animation, inView]);

  return (
    <div className="App__content-block--100 Company__block">
      <div className="App__container">
        <h2 className="App__section-title">
          Work &amp; <b>Experience</b>
        </h2>
        <p className="App__section-copy">
          I've had the pleasure to build applications for some truly awesome
          companies:
        </p>

        <motion.div
          ref={ref}
          animate={animation}
          variants={container}
          initial={false}
          className="Company__block-grid"
        >
          {COMPANIES.map(({ image, name }, index) => {
            const iconSrc = `${staticUrlPath}/companies/${image}`;

            return (
              <motion.div
                variants={item}
                key={`company_${name}_${index}`}
                className="Company__block-grid-item"
              >
                <figure className="Company__logo-wrapper">
                  <img
                    className="Company__logo"
                    src={iconSrc}
                    alt="Company logo"
                    title={name}
                  />
                </figure>
              </motion.div>
            );
          })}
        </motion.div>
      </div>
    </div>
  );
};
