import { WebpImage } from '../../../components/UI/Image';
import { ExternalLink } from '../../../components/UI/Link';
import vimeoImg from './vimeo_yds_intro.jpg';
import vimeoImgWebp from './vimeo_yds_intro.webp';

export const CommunityBlock = () => {
  return (
    <div className="App__content-block--100 Promotion__block">
      <div className="App__container App__container--flex">
        <div className="App__column App__column--left">
          <p className="Promotion__category">Community</p>
          <h3 className="App__section-title">
            Yamagata <br />
            <b>Developers Society</b>
          </h3>
          <p className="Promotion__copy">
            Building a community for developers, designers and students
          </p>
          <ExternalLink
            href="https://yamagata-developers-society.github.io/blog/welcome/"
            className="Button Button--primary Button--rounded"
            title="Visit the YDS Blog"
          >
            Read our blog
          </ExternalLink>
        </div>
        <div className="App__column App__column--right">
          <ExternalLink href="https://vimeo.com/303992016">
            <WebpImage
              src={vimeoImg}
              webp={vimeoImgWebp}
              className="App__responsive-media App__styled-media"
              alt="Intro to Yamagata Developers Society"
              title="Watch on vimeo"
            />
          </ExternalLink>
        </div>
      </div>
    </div>
  );
};
