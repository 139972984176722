import { Link } from 'react-router-dom';
import './styles.css';
import errorImg from './error_404.svg';

const Error404 = () => {
  return (
    <div className="Error404__wrapper">
      <img src={errorImg} className="Error404__img" alt="404 Error" />
      <p className="Error404__title">Oops.. Page Not Found</p>
      <p className="Error404__copy">
        The page you’re looking for doesn't seem to exist.
      </p>
      <div className="Error404__actions">
        <Link className="Button Button--primary Button--rounded" to="/">
          Top
        </Link>
      </div>
    </div>
  );
};

export default Error404;
