import { motion, AnimatePresence } from 'framer-motion';

const WithAnimatedFadeInOut = ({
  isVisible,
  renderComponent,
}: {
  isVisible: boolean;
  renderComponent: () => React.ReactNode;
}) => (
  <AnimatePresence>
    {isVisible && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: 'easeInOut' }}
      >
        {renderComponent()}
      </motion.div>
    )}
  </AnimatePresence>
);

export default WithAnimatedFadeInOut;
