import { useCallback, useState } from 'react';
import { SkeletonImage } from '../LoadingSkeleton';
import type {
  WebpImage as WebpImageType,
  SkeletonImage as SkeletonImageType,
} from '../../../types/componentTypes';

export const WebpImage = ({
  webp,
  src,
  alt,
  title,
  className,
  onLoad,
}: WebpImageType) => (
  <picture>
    <source srcSet={webp} type="image/webp" />
    <source srcSet={src} />
    <img
      src={src}
      alt={alt}
      title={title}
      onLoad={onLoad}
      className={className}
    />
  </picture>
);

type LazyLoadWebpImageType = SkeletonImageType & WebpImageType;

export const LazyLoadWebpImage = ({
  height,
  width,
  className,
  ...rest
}: LazyLoadWebpImageType) => {
  const [isLoaded, setLoaded] = useState(false);
  const onLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  const classNames = className?.split(' ') || [];
  const [activeClass, hiddenClass] = classNames;

  return (
    <>
      {!isLoaded && <SkeletonImage height={height} width={width} />}
      <WebpImage
        {...rest}
        className={isLoaded ? activeClass : hiddenClass}
        onLoad={onLoad}
      />
    </>
  );
};
