import { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import type { Skills as SkillsInterface } from '../../types/interfaces';
import './styles.css';
import { blankStateContent, foundMatchContent } from './constants';
import ResultsList from '../../components/ResultsList/ResultsList';
import SearchSuggest from '../../components/SearchSuggest/SearchSuggest';
import MessageBlock from '../../components/UI/MessageBlock/MessageBlock';
import { withFadeInMotion } from '../../components/Motion/withFadeInMotion';
import { isObjectEmpty } from '../../components/Utils/helpers';
import ScrollToTopOnMount from '../../components/Utils/ScrollToTopOnMount';
import { SKILLS } from '../../data/constants';

interface Props {}

interface State {
  skills: SkillsInterface;
  filteredSkills: SkillsInterface;
  foundMatch: boolean;
  emptyResult: boolean;
}

class Skills extends PureComponent<Props, State> {
  state: State = {
    skills: SKILLS,
    filteredSkills: SKILLS,
    foundMatch: false,
    emptyResult: false,
  };

  closeMsg() {
    this.setState({
      foundMatch: false,
    });
  }

  handleChange(searchTerm: string) {
    let filteredSkills = {};
    let foundMatch = false;

    Object.keys(this.state.skills).map((key) => {
      const result = this.state.skills[key].filter((skill: string) => {
        if (skill.toLowerCase() === searchTerm.toLowerCase()) {
          foundMatch = true;
        }

        return skill.toLowerCase().includes(searchTerm.toLowerCase());
      });

      // if the array is empty return nothing
      if (result.length < 1) {
        return false;
      }

      // @ts-ignore: TODO: TSFixMe -> Unknown error
      return (filteredSkills[key] = result);
    });

    this.setState({
      filteredSkills,
      foundMatch: foundMatch,
      emptyResult: isObjectEmpty(filteredSkills),
    });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Skills</title>
        </Helmet>
        <ScrollToTopOnMount />
        <div className="Skills__hero">
          <div className="Skills__content App__container">
            <h1 className="App__section-title">
              What <b className="App__highlight">skills</b> are you looking for?
            </h1>
            <SearchSuggest
              handleBubbleEvent={(value: string) => {
                this.handleChange(value);
              }}
            />
          </div>
        </div>
        <div className="App__content-block--50">
          <div className="App__container SearchResults__container">
            <ResultsList skills={this.state.filteredSkills} />
            <MessageBlock
              content={blankStateContent}
              isVisible={this.state.emptyResult}
            />
            <MessageBlock
              content={foundMatchContent}
              isVisible={this.state.foundMatch}
              handleClick={() => {
                this.closeMsg();
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withFadeInMotion(Skills);
