import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { SkeletonImage as SkeletonImageType } from '../../../types/componentTypes';

const SkeletonContainer = styled.div`
  line-height: 1;
`;

export const SkeletonImage = ({
  height,
  width = '100%',
}: SkeletonImageType) => (
  <SkeletonContainer>
    <Skeleton width={width} height={height} />
  </SkeletonContainer>
);
