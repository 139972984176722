export const PROJECTS = [
  {
    name: 'naexguide',
    themeColor: '#fdc664',
    title: 'Naexguide',
    description: 'Search Narita Express train times when traveling to Tokyo',
    overview:
      'To build this app, I wrote a custom script to fetch and format train time data from multiple urls. I designed the basic app layout using Sketch, and wrote the code for filtering and displaying train times using React with Flow typechecking. Deployment is handled via Github and Netlify. DNS was configured using AWS R5 and Netlify.',
    dates: 'July 2019 - Present',
    role: 'FE Developer, UX/UI',
    category: 'personal',
    image: 'projects/naexguide',
    url: 'https://naexguide.netlify.app/',
    technologies: ['react', 'flow', 'netlify'],
    otherTools: ['github'],
    contributions: [
      'Wrote custom script for scraping train time data from remote sources, formatting, and storing data as local json files',
      'Designed and built custom search component, which allows users to find trains by time and starting/ending point',
      'Created switch component based on similar implementation used in Google flights to allow users to switch start and end points with a click of a single button',
      'Configured domain using AWS R5 and set up automatic deployment via Netlify',
      'Implemented react-snapshot, react-helmet and utilized Google search console to make site SEO friendly and indexed on Google',
    ],
  },
  {
    name: 'yds',
    themeColor: '#404040',
    title: 'Yamagata Developers Society',
    description:
      'Blog for YDS built with GatsbyJS template and deployed using GitHub pages',
    overview:
      'Yamagata Developers Society is a community of professionals sharing knowledge. This is a group I co-founded with a fellow engineer that has grown to about 15 active members. We hold monthly technology conferences and knowledge sharing sessions. This blog, built with GatsbyJS, is a portal for sharing information from our events.',
    dates: 'Dec 2018 - Present',
    role: 'Founder, Organizer, Developer',
    category: 'personal',
    image: 'projects/yds',
    url: 'https://yamagata-developers-society.github.io/blog/welcome/',
    technologies: ['gatsbyjs', 'github', 'circleci'],
    otherTools: [],
    contributions: [
      'Set up public repository and built foundation for blog with full documentation on contributing and  development',
      'Created scripts for local development and automatic deployment through gh-pages',
      'Added syntax highlighting feature for articles using prismjs and gatsby-remark',
    ],
  },
  {
    name: 'branch-name-generator',
    themeColor: '#5e6df2',
    title: 'Branch Name Generator',
    description: 'Productivity tool to generate standardized branch names',
    overview:
      'I designed and built this React app to streamline my workflow with a tool that speeds up the process of generating branch names. Utilizing local storage, users can customize their settings and save defaults in the browser.',
    dates: 'Jan 2020',
    role: 'FE Developer, UX/UI',
    category: 'personal',
    image: 'projects/branch_name_generator',
    url: 'https://onjo7.csb.app/',
    technologies: ['react'],
    otherTools: [],
    contributions: [
      'Built custom slide-right modal, confirmation dialogs and confirm action switches from scratch',
      'Created reusable functions for saving, editing, fetching and deleting keys in local storage',
      'Added animations for transition of elements and made tool fully accessible',
      'Added ability for users to store settings to customize their experience',
    ],
  },
  {
    name: 'gengo-pad',
    themeColor: '#ff3d48',
    title: 'GengoPad',
    description:
      'From concept & wireframe to prototype, a new study tool for language learning',
    overview:
      'Another side project I used to hone my design and UX/UI skills. This is a prototype built in Sketch with a clickable invision prototype. The goal was to create a desktop app specifically for language learning which could help users maintain an organized journal of learned phrases for multiple languages.',
    dates: 'Nov 2018 - Dec 2018',
    role: 'UX/UI',
    category: 'personal',
    image: 'projects/gengo_pad',
    url: 'https://invis.io/5YPWNT2297P',
    technologies: ['sketch', 'invision'],
    otherTools: [],
    contributions: ['Created prototype with sketch and gallery using Invision'],
  },
  {
    name: 'gengo-app',
    themeColor: '#84def7',
    title: 'GengoApp',
    description:
      'Learn Japanese faster with this study tool! Built with Angular 4 + Firebase',
    overview:
      'I built this Angular 4/Firebase app in my spare time, wanting to create a better way to keep track of the phrases I learn in Japanese. Using the Bulma framework and AngularFirebase starter template, I used tools like Sketch to plan the design, and hand-coded this application from end-to-end.',
    dates: 'July 2017 - Present',
    role: 'Full-stack Development, UX/UI',
    category: 'personal',
    image: 'projects/gengo_app',
    url: '',
    technologies: ['angular', 'typescript', 'sass', 'firebase', 'google-cloud'],
    otherTools: ['github'],
    contributions: [
      'Set up Firebase and data models for storing data visible to only currently logged in user',
      'Wrote all frontend and backend code for saving, editing, deleting and filtering posts',
      'Set up Google cloud functions connected with Google Translate API to automatically translate user input phrases',
      'Created custom logic for detecting Japanese or English with visualization for user',
    ],
  },
  {
    name: 'stay-japan-pass',
    themeColor: '#ea7b63',
    title: 'STAY JAPAN PASS',
    description:
      'Built with handlebars.js to deliver over 330 static pages with one command',
    overview:
      'Given a task to deliver over 100 static pages in 3 languages with a very tight deadline, I devised a plan to use the Foundation 6 static site generator with handlebars.js templates to quickly and efficiently do the job. By hand-coding multiple components & templates and engineering solutions for adapting content from yaml files using handlebars.js helpers and Foundation/panini flat file generator,  and helpers, I was able to get the job done in under 2 weeks with 2 other developers.',
    dates: 'Oct 2018',
    role: 'Lead FE Developer',
    category: 'professional',
    image: 'projects/stay_japan_pass',
    url: '',
    technologies: ['es6', 'handlebars', 'sass', 'foundation', 'gitlab'],
    otherTools: [],
    contributions: [
      'Architected system for storing translations via Yaml files, which would automatically generate over 300 static pages',
      'Created the landing page and all templates used accross the site',
      'Wrote JavaScript components like scroll to top, loading animations, language switchers and guest calculators from scratch',
      'Built custom Handlebars helpers, mixins and blocks for resolving translations, file paths and reusing components across site',
    ],
  },
  {
    name: 'spike-matching',
    themeColor: '#d7eaea',
    title: 'Spike Matching LP',
    description:
      'Responsive landing page for M&A matching platform built with Ruby on Rails',
    overview:
      'Responsive landing page for M&A matching platform built in Ruby on Rails from mockups in Sketch. Releasing this site lead to an increase in our company stock price, and we were featured in several news and technology blogs across Japan.',
    dates: 'Oct 2017',
    role: 'Lead FE Engineer',
    category: 'professional',
    image: 'projects/spike_matching',
    url: '',
    technologies: ['rails', 'javascript', 'sass', 'github'],
    otherTools: [],
    contributions: [
      'Hand coded all components from scratch and refactored code to make existing components easier to reuse',
      'Implemented full screen background video using HTML5 video and custom scripts for easing in playback and entrance animations',
    ],
  },
  {
    name: 'weekend-code-challenge',
    themeColor: '#1B30AA',
    title: 'Weekend Code Challange',
    description:
      'A collection of real-world coding puzzles and their solutions',
    overview:
      "Weekend Code Challenge is a collection of coding problems and their solutions. It's a compendium of real-world challenges and coding puzzles that can sharpen your coding skills, but that are small enough to complete in your free time over the weekend.",
    dates: 'Jan 2021',
    role: 'Author',
    category: 'personal',
    image: 'projects/weekend_code_challenge',
    url: 'https://github.com/shin10kudev/weekend-code-challenge',
    technologies: ['github', 'es6'],
    otherTools: [],
    contributions: [
      'Create problems & challenges with detailed explainations for each solution',
      'Set up the repo with contribution guidelines, PR and issue templates and guide to using the project',
    ],
  },
];

export const SKILLS = {
  Languages: ['JavaScript', 'ES6', 'Ruby', 'PHP'],
  Frontend: ['HTML5', 'CSS3', 'styled components', 'CSS in JS', 'Sass/SCSS'],
  Frameworks: ['React', 'React Native', 'Next.js', 'Ruby on Rails'],
  Build: ['Gulp', 'Node.js', 'Webpack'],
  'Package Managers': ['npm', 'yarn', 'bower'],
  'Version Control': ['Git', 'GitHub', 'GitLab'],
  Data: ['GraphQL', 'Firebase', 'MySQL'],
  Tools: ['Bash', 'Visual Studio Code', 'Sketch', 'JIRA', 'Confluence'],
  Methodology: [
    'Functional Programming',
    'OOP',
    'BEM/OOCSS',
    'Responsive Design',
  ],
  'Other Skills': ['Management', 'Agile/Scrum', 'Public speaking', 'Teaching'],
};

export const SOCIAL_ACCOUNTS = [
  {
    name: 'LinkedIn',
    icon: 'linkedin.svg',
    url: 'https://www.linkedin.com/in/ethindman/',
  },
  {
    name: 'GitHub',
    icon: 'github.svg',
    url: 'https://github.com/shin10kudev',
  },
  {
    name: 'Medium',
    icon: 'medium.svg',
    url: 'https://medium.com/@shinjukudev',
  },
  {
    name: 'YDS Blog',
    icon: 'yds.svg',
    url:
      'https://yamagata-developers-society.github.io/blog/author/elliott-hindman/',
  },
];
