import successImg from './skill_success.svg';
import noResultImg from './no_result.svg';

export const blankStateContent = {
  image: noResultImg,
  title: 'Did I miss something?',
  copy: "Well, there's always something new to learn! Try searching 'React' or 'GraphQL'",
};

export const foundMatchContent = {
  image: successImg,
  title: 'Hey! I know that one!',
  copy: 'Try your search again to see what else I can do.',
  classNames: 'MessageBlock--fixed',
};
