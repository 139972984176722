import { Helmet } from 'react-helmet';
import { Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import './styles/vendor/css/normalize.css';
import './styles/vendor/css/foundation.typography.custom.css';
import './styles/variables.css';
import './styles/app.css';
import Header from './components/Layout/Header/Header';
import Projects from './components/Projects/Projects';
import Home from './pages/home';
import Profile from './pages/profile';
import Skills from './pages/skills';
import Error404 from './pages/error';

function App() {
  return (
    <>
      <Helmet titleTemplate="%s - Elliott Creates">
        <title>Elliott Hindman</title>
        <meta
          name="description"
          content="Elliott Hindman - Software Engineer/Engineering Manager, founder of Elliott Creates, Yamagata Developers Society and Developers Study Group."
          key="description"
        />
      </Helmet>
      <Header />
      <div className="App__view">
        <AnimatePresence exitBeforeEnter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/skills" element={<Skills />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/projects/*" element={<Projects />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </AnimatePresence>
      </div>
    </>
  );
}

export default App;
