import { Helmet } from 'react-helmet';
import './styles.css';
import { SOCIAL_ACCOUNTS } from '../../data/constants';
import Social from '../../components/UI/Social/Social';
import ScrollToTopOnMount from '../../components/Utils/ScrollToTopOnMount';
import { withFadeInMotion } from '../../components/Motion/withFadeInMotion';

const Profile = () => (
  <>
    <Helmet>
      <title>Profile</title>
    </Helmet>
    <ScrollToTopOnMount />
    <div className="App__content-block--50 Profile__block">
      <div className="App__container App__container--flex">
        <div className="App__column">
          <h1 className="App__section-title">
            Creative, Resourceful,{' '}
            <b className="App__swish-highlight">Driven</b>.
          </h1>
          <div className="Profile__summary">
            <p className="App__section-copy">
              I'm a software engineer and engineering manager with over a decade of industry
              experience and a great passion for UX/UI.
            </p>
            <p className="App__section-copy">
              With a background in fullstack development and teaching, I bring a
              strong set of problem solving and communication skills to the
              table, and know how to leverage agile methodologies to develop high-impact products with international, cross-platform teams.
            </p>
          </div>
          <Social accounts={SOCIAL_ACCOUNTS} />
        </div>
      </div>
    </div>
  </>
);

export default withFadeInMotion(Profile);
